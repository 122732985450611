import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import styles from '../index.module.scss'
import FAQ from '../../components/FAQ'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {

  render() {

    return (

      <Layout location={this.props.location}>

        <SEO
          title="how to upload a podcast to youtube"
          description="Step by step guide how to upload and post your podcast to YouTube."
        />

        <article>

          <h1>How to upload a podcast to YouTube</h1>


          <section>

            <p>Its true, You cannot upload your podcast directly to YouTube. But we have another way:</p>


            <h2>Convert your Podcast to a YouTube Video</h2>
            <ol>
              <li id='step1'>
                <strong>Convert your audio file</strong>
                <p>Convert your audio podcast to a video using EchoWave</p>
              </li>
              <li id='step2'>
                <strong>Add an Hero image for the video</strong>
                <p>Choose a catchy thumbnail image for your video and the background of your video.</p>
              </li>
              <li id='step3'>
                <strong>Upload to YouTube</strong>
                <p>Automatically post the Video to YouTube using our integration, or the Creator Studio.</p>
              </li>
            </ol>


            <script type="application/ld+json">{`
                            {
                                "@context": "http://schema.org",
                                "@type": "HowTo",
                                "name": "How to put podcast on youtube",
                                "description": "Convert your podcast into a YouTube video.",
                                "image": {
                                "@type": "ImageObject",
                                "url": "https://echowave.io/images/how-to-share-a-postact-on-instagram/share-an-audio-snippet.jpg",
                                "height": "491",
                                "width": "900"
                            },
                                "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "currency": "USD",
                                "value": "0"
                            },
                                "tool": [
                            {
                                "@type": "HowToTool",
                                "name": "YouTube Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "EchoWave Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "PC or Mobile Phone"
                            }
                                ],
                                "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Convert your audio file",
                                    "url": "https://echowave.io/how-to-post-your-podcast-on-youtube/#step1",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Convert your audio podcast to a video using EchoWave"
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-1.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Add an Hero image for the video",
                                    "url": "https://echowave.io/how-to-post-your-podcast-on-youtube/#step2",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Choose a catchy thumbnail image for your video and the background of your video."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-2.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Upload to YouTube",
                                    "url": "https://echowave.io/how-to-post-your-podcast-on-youtube/#step3",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Automatically post the Video to YouTube using our integration, or the Creator Studio."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-3.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                }
                                ],
                                "totalTime": "PT10M"
                            }`}
            </script>



          </section>

          <section>
            <GetStartedOrChat cta="Audio podcast on YouTube" />
            <br />
          </section>

          <section>

            <p>Podcasts start when you have a digital audio file with content, 
              usually someone talking about a topic or group of people discussing a subject or 
              an interview-style of setting with QA. Only having digital audio files with some content
              online does not make it a Podcast. 
              To turn your recorded sound content into a Podcast you need a podcast content hosting server. 
            </p>

            <p>
            Some people might think podcasting is for people with techie mindset, 
            this might have been true in the past when internet speed was super slow (dial-up anyone), 
            but we have moved on exponentially since then. 
            Anyone with the internet can now stream audio files and subscribe to a podcast,
            which is a good thing but at the same time, more and more podcasts are being uploaded, 
            because of this you might find it hard to be noticed by your audience.
              <img src="/images/podcast-into-video.jpg"  alt="Mobile Phone Next to Head Phone"/>

            </p>

            <p>
              As the market grows and more contents are easily available, 
              standing out in the crowd becomes an important strategy. 
              Uploading your audio file onto a stander 
              hosting site might not give you the extra edge you need to reach out to your audience. </p>
          <p>
            So the next natural step would be to set up a social network 
            (<b> <i> Facebook, Twitter, Instagram </i></b> and so on..)  
            account and share your link, updates and build an audience that way. 
          </p>

          <p>
            <b>Why stop there?</b> You can also create a cool <b>audiogram</b> of your podcast, 
            reach out to more audience by sharing your audiogram on YouTube by 
            turning your audio file into a video with background image and wave animation. 
          </p>
          </section>
        </article>


      </Layout>

    )
  }

}

export default Page
